exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-code-of-conduct-tsx": () => import("./../../../src/pages/about/code-of-conduct.tsx" /* webpackChunkName: "component---src-pages-about-code-of-conduct-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-about-join-us-tsx": () => import("./../../../src/pages/about/join-us.tsx" /* webpackChunkName: "component---src-pages-about-join-us-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-consult-tsx": () => import("./../../../src/pages/consult.tsx" /* webpackChunkName: "component---src-pages-consult-tsx" */),
  "component---src-pages-document-index-tsx": () => import("./../../../src/pages/document/index.tsx" /* webpackChunkName: "component---src-pages-document-index-tsx" */),
  "component---src-pages-document-project-lifecycle-tsx": () => import("./../../../src/pages/document/project-lifecycle.tsx" /* webpackChunkName: "component---src-pages-document-project-lifecycle-tsx" */),
  "component---src-pages-document-tech-stack-tsx": () => import("./../../../src/pages/document/tech-stack.tsx" /* webpackChunkName: "component---src-pages-document-tech-stack-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-service-application-development-tsx": () => import("./../../../src/pages/service/application-development.tsx" /* webpackChunkName: "component---src-pages-service-application-development-tsx" */),
  "component---src-pages-service-index-tsx": () => import("./../../../src/pages/service/index.tsx" /* webpackChunkName: "component---src-pages-service-index-tsx" */),
  "component---src-pages-service-internet-of-things-tsx": () => import("./../../../src/pages/service/internet-of-things.tsx" /* webpackChunkName: "component---src-pages-service-internet-of-things-tsx" */),
  "component---src-pages-service-system-integration-tsx": () => import("./../../../src/pages/service/system-integration.tsx" /* webpackChunkName: "component---src-pages-service-system-integration-tsx" */),
  "component---src-pages-service-web-development-tsx": () => import("./../../../src/pages/service/web-development.tsx" /* webpackChunkName: "component---src-pages-service-web-development-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */)
}

